@charset "UTF-8";

html {
    font-size: 16px;
    scroll-behavior: smooth;
    /* 1rem */
}

/* Указываем box sizing */
*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    background: #fff;
    font-family: "Outfit";
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]),
a[class] {
    text-decoration-skip-ink: auto;
    text-decoration: none;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article>*+* {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
    * {
        -webkit-animation-duration: 0.01ms !important;
        animation-duration: 0.01ms !important;
        -webkit-animation-iteration-count: 1 !important;
        animation-iteration-count: 1 !important;
        -webkit-transition-duration: 0.01ms !important;
        -o-transition-duration: 0.01ms !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}


.indents {
    margin-left: 3vw;
    margin-right: 3vw;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 3vw;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-right: 3vw;
    width: 100%;
    position: fixed;
    background: #FFD74B;
}

.logo {
    cursor: pointer;
}

.nav {
    display: flex;
    justify-content: space-between;
    gap: 3vw;

}

.nav a {
    color: #445371;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.btn {
    background: #1E8B15;
    border-radius: 6px;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.bg-yellow {
    height: 100vh;
    padding-top: calc(95px + 74px);
    background: #FFD74B;
}

.info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 48px;
}

.info-soc {
    display: flex;
    flex-direction: column;
    gap: 38px;
}

.info-text {
    display: flex;
    gap: 70px;
    flex-direction: column;
}

.info-soc__contract {
    display: flex;
    gap: 2px;
    justify-content: flex-start;
    align-items: center;
}

.link-soc {
    display: flex;
    gap: 2px;
}

.link-soc a {
    background: #FFFFFF;
    padding: 10px;
    border-radius: 24px;
}

.contract {
    padding: 8px 12px;
    border-radius: 24px;
    gap: 8px;
    display: flex;
    background: #fff;
    max-width: 430px;
    justify-content: center;
}

.contract img {
    cursor: pointer;
}

.contract p {
    font-family: "Outfit";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

}

.info-soc__btns {
    display: flex;
    gap: 8px;
}

.btn-wh {
    background: #fff;
    display: flex;
    border-radius: 6px;
    padding: 16px 20px;
    gap: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;

}


.header__burger,
.mob-menu {
    display: none;
}

.about {
    display: flex;
    justify-content: center;
    gap: 88px;
    align-items: flex-start;
    margin-top: 82px;
    padding-top: 100px;
}

.about-text {
    max-width: 651px;
    flex-direction: column;
    display: flex;
    flex-basis: 50%;
    flex-shrink: 1;
    gap: 32px;
}


.about-title {
    text-transform: uppercase;
    font-family: "Outfit";
    font-size: 82px;
    font-weight: 400;
    line-height: 82px;
    letter-spacing: -0.04em;
    text-align: left;

}

.about-btns {
    display: flex;
    gap: 12px;
    cursor: pointer;
    padding-bottom: 209px;
}

.btn-grey {
    padding: 20px;
    background: #4453711A;
    border-radius: 6px;
    text-align: center;
}

.btn-grey a {
    color: #000;
}

.token {
    background: #7D99FF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 22px;
}

.token-title {
    padding-top: 105px;
    font-family: "Outfit";
    font-size: 82px;
    font-weight: 400;
    line-height: 82px;
    letter-spacing: 0em;
    color: #FFF;
    text-transform: uppercase;
}

.token-info {
    display: flex;
    align-items: center;
}

.token-info__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.token-info__info p {
    font-family: "Outfit";
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #797979;
}

.total-supply span,
.sum span {
    font-family: "Outfit";
    font-size: 40px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
}

.total-supply div {
    display: flex;
    gap: 4px;
}


.man {
    color: #95A9D2;
    font-family: "Manrope";
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;

}

.sum {
    display: flex;
    gap: 8px;
}

.total-supply,
.taxes,
.masculinity {
    background: #fff;
    padding: 24px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.taxes,
.masculinity {
    flex-basis: 50%;
}

.taxes-info {
    position: relative;
    display: flex;
    gap: 4px;
}

.quest {
    position: relative;
    display: flex;
    flex-direction: column;
}

.popup-quest {
    position: absolute;
    z-index: 1;
    bottom: 40px;
    right: 0;
    width: 200px;
    /* Отступ от верхней границы контейнера */
    background-color: #212429;
    padding: 12px;
    border-radius: 8px;
    color: #FFF;
    font-family: "Outfit";
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    transform: translate(46%,0);
}

.popup-quest img {
    position: absolute;
    z-index: 2;
    top: 100px;
    left: 50%;
    transform: translate(-50%,0);
}

/* POPUP */
#popup {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
}

#popup-content {
    text-transform: uppercase;
    background: #1E8B15;
    padding: 20px;
    margin: 0 auto;
    text-align: center;
    font-family: "Outfit";
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
}


.taxes-info img {
    cursor: pointer;
}

.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-top: 54px;
    background: #FFD74B;
    padding-bottom: 8px;

}

.footer p {
    text-transform: uppercase;
    max-width: 893px;
    font-family: "Outfit";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
}

.footer span {
    font-family: "Outfit";
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #78672D;
}


#text-to-copy {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #445371;
}


@media (max-width: 320px) {

    .total-supply span,
    .sum span {
        font-size: 30px;
    }
}



@media (max-width: 375px) {
    .sum {
        flex-direction: column;
    }

    .btn-wh {
        text-align: center;
    }

    #text-to-copy {
        max-width: 100px;
    }
}

@media (max-width: 425px) {
    .info-soc__contract {
        flex-direction: column;
        gap: 20px;
    }

    .info-soc__btns {
        justify-content: center;
    }

    .about {
        flex-direction: column-reverse;
        margin-top: 0;
    }

    .about-title {
        text-align: center;
        font-size: 40px;
        line-height: 50px
    }

    .about-btns {
        padding-bottom: 100px;
    }

    .token-title {
        font-size: 50px;
    }

    .token-info {
        flex-direction: column-reverse;
    }
}


@media (max-width: 768px) {

    .header .btn,
    .nav {
        display: none;
    }

    .btn.active {
        display: block;
    }

    .info {
        flex-direction: column;
        align-items: center;
    }

    .header-soc {
        display: flex;
        flex-direction: column;
        gap: 15px;
        font-family: "Outfit";
        font-size: 25px;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: center;
    }

    .header-soc a {
        text-transform: uppercase;
        color: #fff;
    }

    .links {
        display: flex;
        gap: 10px;
    }

    .wallet {
        font-size: 38px;
        text-transform: uppercase;
        color: #FFF;
    }

    .header-menu__item a {
        font-size: 38px;
        text-transform: uppercase;
        color: #FFF;
    }

    .erc404-info__text p {
        font-size: 40px;
    }

    .erc404 {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: unset;
    }

    .main-logo__text {
        display: none;
    }

    .mob-menu__items {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }

    .mob-menu-link a {
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 110%;
        color: #fff;
    }

    .mob-menu {
        position: fixed;
        /* background-color: rgba(255, 80, 0, 0); */
        /*clip-path: circle(0% at 100% 0); */
        transition: all ease-in-out 200ms;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 8;
        pointer-events: none;
    }

    .mob-menu.active {
        /*clip-path: circle(100% at 100% 0); */
        background-color: rgba(28, 28, 28, 0.9);
        pointer-events: all;
        display: block;
    }

    .header__burger {
        /* margin-top: 30px;
        margin-right: 24px; */
        display: block !important;
        position: relative;
        width: 30px;
        height: 20px;
        z-index: 105;
    }

    .header__burger span {
        background-color: #fff;
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        top: 9px;
        transition: all 0.3s ease 0s;
    }

    .header__burger:before,
    .header__burger:after {
        content: '';
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        transition: all 0.3s ease 0s;
    }

    .header__burger:before {
        top: 0;
    }

    .header__burger:after {
        bottom: 0;
    }

    .header__burger.active:before {
        transform: rotate(45deg);
        top: 9px;
    }

    .header__burger.active:after {
        transform: rotate(-45deg);
        bottom: 9px;
    }

    .header__burger.active span {
        transform: scale(0);
    }
}